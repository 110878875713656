<template>
    <div>
        <div>
            <input type="text" v-model="msg" placeholder="Message" />
            <button @click="send_message">Message</button>
        </div>
        <div>Messages</div>
        <div v-for="(message, i) in $store.state.messages" :key="`mes_${i}`">
            {{ message }}
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'chat',
    data() {
        return {
            msg: ''
        }
    },
    methods: {
        ...mapMutations(['addMessage', 'removeConnection']),

        send_message() {
            this.addMessage(this.msg);
            this.$store.state.connections.forEach(c => {
                if(!c._open) return this.removeConnection(c.connectionId);
                c.send({type: 'message', msg: this.msg});
            });
            this.msg = '';
        }
    }
}
</script>