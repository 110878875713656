import { mapMutations } from "vuex";

export default {
    methods: {
        ...mapMutations(['removeConnection', 'addMessage', 'set_current_screen_stream', 'set_current_voice_stream', 'set_current_voice_status', 'set_share_voice_streams', 'set_screen_view_status', 'set_screen_share_request']),

        setListeners(conn) {
            conn.on("data", data => {
                if(data.type == 'message') {
                    this.addMessage(data.msg);
                } else if(data.type == 'request_screen_share') {
                    this.start_screen_share(data.connection_id);
                } else if(data.type == 'request_voice') {
                    this.start_voice_share(data.connection_id);
                } else if(data.type == 'reject_screen_share') {
                    console.log('Rejected Screen Share');
                    this.set_screen_view_status(false);
                    this.set_screen_share_request('');
                } else if(data.type == 'reject_voice_share') {
                    console.log('Rejected Voice Share');
                } else if(data.type == 'cursor_point') {
                    let cursor = document.querySelector(`#cursor_${conn.connectionId}`);
                    if(cursor == undefined) {
                        cursor = document.createElement('span');
                        cursor.innerText = conn.peer;
                        cursor.classList.add('user_cursor');
                        cursor.id = `cursor_${conn.connectionId}`;
                        document.querySelector('body').append(cursor);
                    }
                    let dev_wi = window.innerWidth > data.c.w ? window.innerWidth / data.c.w : 1;
                    let dev_he = window.innerHeight > data.c.h ? window.innerHeight / data.c.h : 1;
                    cursor.style.top = `${data.c.t * dev_he}px`
                    cursor.style.left = `${data.c.l * dev_wi}px`
                } else if(data.type == 'stopped_screen_share') {
                    if(data.connection_id == this.$store.state.screen_share_request) {
                        this.set_screen_view_status(false);
                        this.set_screen_share_request('');
                    }
                } else if(data.type == 'exit_screen_share') {
                    document.querySelector(`#cursor_${data.connection_id}`).remove();
                }
            });
            conn.on("close", () => {
                this.removeConnection(conn.connectionId);
            });
            conn.on("error", err => {
                console.error(err);
            });
        },
        start_screen_share(connection_id) {
            if(this.$store.state.current_screen_stream != '' && this.$store.state.current_screen_stream.active) this.connect_screen_share(connection_id);
            else {
                var getUserMedia = navigator.mediaDevices.getDisplayMedia({
                    video: true,
                });
                getUserMedia.then(stream => {
                    this.set_current_screen_stream(stream);
                    this.connect_screen_share(connection_id);
                }).catch(() => {
                    this.$store.state.connections.filter(c => c.connectionId == connection_id).forEach(c => {
                        c.send({ type: 'reject_screen_share' })
                    });
                });
            }
        },
        connect_screen_share(connection_id) {
            this.$store.state.connections.filter(c => c.connectionId == connection_id).forEach(c => {
                this.$peer.call(c.peer, this.$store.state.current_screen_stream, {
                    metadata: { type: "screen_share" }
                });
            },
            err => {
                console.log("Failed to get local stream", err);
            });
        },
        start_voice_share(connection_id) {
            console.log(this.$store.state.current_voice_stream)
            if(this.$store.state.current_voice_stream != '' && this.$store.state.current_voice_stream.active) this.connect_voice_share(connection_id);
            else {
                var getUserMedia =  navigator.getUserMedia  || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                getUserMedia({ video: false, audio: true }, stream => {
                    this.set_current_voice_stream(stream);
                    this.connect_voice_share(connection_id);
                    this.set_current_voice_status(true);
                }, () => {
                    this.$store.state.connections.filter(c => c.connectionId == connection_id).forEach(c => {
                        c.send({ type: 'reject_voice_share' })
                    });
                });
            }
        },
        connect_voice_share(connection_id) {
            this.$store.state.connections.filter(c => c.connectionId == connection_id).forEach(c => {
                let call = this.$peer.call(c.peer, this.$store.state.current_voice_stream, {
                    metadata: { type: "voice_share" }
                });
                call.on("stream", (voiceStream) => {
                    this.set_share_voice_streams(voiceStream);
                });
            },
            err => {
                console.log("Failed to get local stream", err);
            });
        }
    }
}