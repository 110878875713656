import Vue from 'vue'
import App from './App.vue'
import VuePeerJS from 'vue-peerjs'
import Peer from 'peerjs'
import store from './store'

Vue.config.productionTip = false
Vue.use(VuePeerJS, new Peer({
    config: {
        iceServers: [
            {
                urls: 'stun:stun.l.google.com:19302'
            },
            {
                urls: 'turn:turn.codeafterbuild.com:3478',
                username: 'soorath',
                credential: 'codeafterbuild'
            }
        ]
    }
}));

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
