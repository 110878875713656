<template>
    <div ref="share_voice">
        
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    mounted() {
        this.play_audio();
    },
    computed: {
        ...mapState({
            share_voice_streams: state => state.share_voice_streams
        })
    },
    watch: {
        share_voice_streams() {
            this.play_audio()
        }
    },
    methods: {
        play_audio() {
            this.clear_audio();
            this.share_voice_streams.forEach(c => {
                let v = document.createElement('audio');
                v.srcObject = c;
                v.addEventListener("loadedmetadata", () => {
                    v.play();
                });
                this.$refs.share_voice.append(v);
            });
        },
        clear_audio() {
            this.$refs.share_voice.innerHTML = '';
        }
    }
}
</script>