import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        connections: [],
        messages: [],
        screen_view_status: false,
        screen_share_request: '',
        current_screen_stream: '',
        current_voice_stream: '',
        current_voice_status: false,
        share_voice_streams: []
    },
    mutations: {
        addConnection(state, conn) {
            state.connections.push(conn);
        },
        removeConnection(state, connection_id) {
            state.connections = state.connections.filter(c => c.connectionId != connection_id);
        },
        addMessage(state, msg) {
            state.messages.push(msg);
        },
        set_current_screen_stream(state, stream) {
            state.current_screen_stream = stream;
        },
        set_current_voice_stream(state, stream) {
            state.current_voice_stream = stream;
        },
        set_current_voice_status(state, status) {
            state.current_voice_status= status;
        },
        set_mute(state) {
            if(state.current_voice_stream != '') state.current_voice_stream.getAudioTracks()[0].enabled = !state.current_voice_stream.getAudioTracks()[0].enabled;
        },
        set_share_voice_streams(state, stream) {
            state.share_voice_streams.push(stream);
        },
        set_screen_share_request(state, id) {
            state.screen_share_request = id;
        },
        set_screen_view_status(state, status) {
            state.screen_view_status = status;
        }
    },
    actions: {
    },
    modules: {
    }
})
