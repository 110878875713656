<template>
    <div>
        <div ref="share_video">
            
        </div>
        <span class="dot_wrap">
            <span class="dot"></span>Click on dot to get tracker
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['stream'],
    data() {
        return {
            start_pt: 0
        }
    },
    mounted() {
        this.render_screen();
    },
    computed: {
        ...mapState({
            status: state => state.screen_view_status
        })
    },
    watch: {
        status() {
            this.render_screen()
        }
    },
    methods: {
        render_screen() {
            this.clear_video();
            if(!this.status) return;
            let v = document.createElement('video');
            v.srcObject = this.stream;
            v.style.maxWidth = '100%';
            v.addEventListener("loadedmetadata", () => {
                v.play();
            });
            v.addEventListener('click', (ev) => {
                this.start_pt = ev.layerY;
            });
            v.addEventListener('mousemove', (ev) => {
                if(this.start_pt == 0) return;
                this.$store.state.connections.filter(c => c.connectionId == this.$store.state.screen_share_request).forEach(c => {
                    c.send({ type: 'cursor_point', c: {t: ev.layerY - this.start_pt, l: ev.layerX, w: v.clientWidth, h: v.clientHeight - this.start_pt} })
                });
            });
            this.$refs.share_video.append(v);
        },
        clear_video() {
            this.$refs.share_video.innerHTML = '';
        }
    }
}
</script>

<style scoped>
.dot_wrap {
    position: fixed;
    top: 0;
    left: calc(100vw / 2);
    display: flex;
}
.dot {
    display: block;
    width: 10px;
    height: 10px;
    background: green;
    margin-right: 10px;
}
</style>