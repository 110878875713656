<template>
    <div>
        <div>
            My Id: {{ peer_id }}<button v-if="$store.state.current_voice_status" @click="mute">{{ $store.state.current_voice_stream.getAudioTracks()[0].enabled ? 'Mute' : 'Unmute' }}</button><button v-if="$store.state.current_screen_stream != ''" @click="stop_screen_share">Stop Screen Share</button>
        </div>
        <connect />
        <chat />
        <screenShare :stream="share_stream" />
        <voiceShare />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

import connect from './components/connect';
import chat from './components/chat';
import screenShare from './components/screen_share';
import voiceShare from './components/voice_share';
import connection_listener from '@/mixins/connection_listener';

export default {
    name: 'app',
    components: {
        connect,
        chat,
        screenShare,
        voiceShare
    },
    mixins: [connection_listener],
    data() {
        return {
            peer_id: '',
            connections: [],
            share_stream: '',
            share_status: false
        }
    },
    created() {
        this.$peer.on("open", (id) => {
            this.peer_id = id;
        });
        this.$peer.on("connection", (conn) => {
            this.addConnection(conn);
            this.setListeners(conn);
        });
        this.$peer.on("disconnected", () => {
            console.log('disconnected');
        });
        this.$peer.on("call", (call) => {
            if(call.metadata.type == "screen_share") {
                call.answer();
                call.on("stream", videoStream => {
                    this.set_screen_view_status(false);
                    this.$nextTick(() => {
                        this.share_stream = videoStream;
                        this.set_screen_view_status(true);
                    });
                });
            } else if(call.metadata.type == "voice_share") {
                if(this.$store.state.current_voice_stream != '') call.answer(this.$store.state.current_voice_stream);
                else {
                    var getUserMedia = navigator.getUserMedia  || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                    getUserMedia({ video: false, audio: true }, (stream) => {
                        this.set_current_voice_stream(stream);
                        call.answer(stream); // Answer the call with an A/V stream.
                        this.set_current_voice_status(true);
                    },
                    err => {
                        console.log("Failed to get local stream", err);
                    });
                }
                call.on("stream", voiceStream => {
                    this.set_share_voice_streams(voiceStream);
                });
            }
        });
    },
    methods: {
        ...mapMutations(['addConnection', 'set_current_voice_stream', 'set_current_voice_status', 'set_mute', 'set_share_voice_streams', 'set_current_screen_stream', 'set_screen_view_status']),

        mute() {
            this.set_mute();
        },
        stop_screen_share() {
            this.$store.state.current_screen_stream.getVideoTracks()[0].enabled = false;
            this.set_current_screen_stream('');
            this.$store.state.connections.forEach(c => {
                c.send({type: 'stopped_screen_share', connection_id: c.connectionId});
            });
            document.querySelectorAll('.user_cursor').forEach(c => {
                c.remove();
            });
        }
    }
}
</script>

<style>
.user_cursor {
    position: fixed;
}
</style>