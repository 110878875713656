<template>
    <div>
        Connect to :
        <input type="text" v-model="connect_to" placeholder="Connect To" />
        <button @click="connect">Connect</button>
        <div>Connections</div>
        <div v-for="conn in $store.state.connections" :key="conn.connectionId">
            {{ conn.connectionId }} -> {{ conn.peer }}
            <button @click="request_screen_share(conn)"> Request Screen Share</button>
            <button v-if="$store.state.screen_share_request ==  conn.connectionId" @click="exit_screen_share(conn)"> Cancel Screen Share</button>
            <button @click="request_voice(conn)"> Request Voice</button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

import connection_listener from '@/mixins/connection_listener';

export default {
    name: 'connect',
    mixins: [connection_listener],
    data() {
        return {
            connect_to: ''
        }
    },
    methods: {
        ...mapMutations(['addConnection', 'set_screen_share_request']),

        connect() {
            const conn = this.$peer.connect(this.connect_to);
            conn.on("open", () => {
                this.addConnection(conn);
                this.setListeners(conn);
            });
            this.connect_to = '';
        },
        request_screen_share(conn) {
            this.set_screen_share_request(conn.connectionId);
            conn.send({type: 'request_screen_share', connection_id: conn.connectionId});
        },
        request_voice(conn) {
            conn.send({type: 'request_voice', connection_id: conn.connectionId});
        },
        exit_screen_share(conn) {
            this.set_screen_view_status(false);
            conn.send({type: 'exit_screen_share', connection_id: conn.connectionId});
        }
    }
}
</script>